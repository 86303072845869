import React, { useState } from "react"
import PatientForm from "./patient-form/patientForm"
import ShipmentForm from "./shipment-form/shipmentForm"
import PackageForm from "./package-form/packageForm"
import OrderSummary from "./summary/orderSummary"
import { useOrderDictionary } from "../../dictionaries/orderDictionaries"
import { withSnackbar } from "react-simple-snackbar"
import snackBarSettings from "../../components/snackBarSettings"
import { trackPromise } from "react-promise-tracker"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import PregnancyDateForm from "./pregnancy-date-form/pregnancyDateForm"
import OrderStepper from "./order-stepper"

const axios = require(`axios`)

const OrderBanner = props => {
  const intl = useIntl()

  const { openSnackbar } = props

  const { shippingMethods, paymentMethods } = useOrderDictionary()
  const [packages, setPackages] = useState([])

  const [page, setPage] = useState(0)

  const [pregnancyDateFormValues, setPregnancyDateFormValues] = useState({
    estimated_childbirth_date: null,
  })

  const [packageFormValues, setPackageFormValues] = useState({
    package_id: null,
    doctor_code: null,
  })

  const [shipmentFormValues, setShipmentFormValues] = useState({
    shipping_method_id:
      shippingMethods.length > 0 ? shippingMethods[0].shipping_method_id : 1,
    payment_method_id:
      paymentMethods.length > 0 ? paymentMethods[0].payment_method_id : 1,
    delivery_date: null,
    address_details: {
      country: "",
      voivodeship: "",
      city: "",
      postal_code: "",
      street: "",
      house_no: "",
      apartment_no: "",
      additional_info: "",
    },
  })

  const [patientFormValues, setPatientFormValues] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    phone: "",
    birth_date: null,
    language_cd: "",
    environment: {
      past_pregnancies: false,
      weight: "",
      height: "",
      children_no: "",
      medication: "",
      risk_factors: "",
      info: "",
    },
    emergency_person: {
      name: "",
      surname: "",
      email: "",
      kinship: "",
      phone: "",
    },
  })

  const [order, setOrder] = useState({ price: 0 })

  const handlePregnancyDateFormSubmit = (formValues, castValues) => {
    const params = {
      estimated_childbirth_date: castValues.estimated_childbirth_date,
    }

    return trackPromise(
      axios
        .get(`${process.env.GATSBY_API_URL}/subscription/order/packages`, {
          params,
        })
        .then(response => {
          setPackages(response.data.data)
          setPackageFormValues({
            package_id: null,
            doctor_code: null,
          })
          setPregnancyDateFormValues(
            Object.assign({
              estimated_childbirth_date: formValues.estimated_childbirth_date,
            })
          )
          setOrder(
            Object.assign(order, {
              environment: {
                estimated_childbirth_date: castValues.estimated_childbirth_date,
              },
            })
          )
          nextPage()
          window.scrollTo(0, 0)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgPackagesError",
              defaultMessage: "Nie udało się pobrać listy pakietów...",
            }),
            4000
          )
        )
    )
  }

  const handleCheckDoctorCode = rawDoctorCode => {
    trackPromise(
      axios
        .get(
          `${process.env.GATSBY_API_URL}/user/registration/check/doctor-code?doctor_code=${rawDoctorCode}`
        )
        .then(res => {
          if (res.data.valid) {
            const params = {
              estimated_childbirth_date:
                order.environment.estimated_childbirth_date,
              doctor_code: rawDoctorCode,
            }

            axios
              .get(
                `${process.env.GATSBY_API_URL}/subscription/order/packages`,
                { params }
              )
              .then(response => {
                setPackages(response.data.data)
                setPackageFormValues({
                  package_id: null,
                  doctor_code: rawDoctorCode,
                })
              })
              .catch(error =>
                openSnackbar(
                  intl.formatMessage({
                    id: "orderBanner_msgPackagesError",
                    defaultMessage: "Nie udało się pobrać listy pakietów...",
                  }),
                  4000
                )
              )
          } else {
            openSnackbar(
              intl.formatMessage({
                id: "orderBanner_msgCodeError",
                defaultMessage: "Kod lekarza nieprawidłowy",
              }),
              2500
            )
          }
        })
        .catch(error => {
          if (error.response) {
            openSnackbar(
              intl.formatMessage({
                id: "orderBanner_msgCodeError",
                defaultMessage: "Kod lekarza nieprawidłowy",
              }),
              2500
            )
          }
        })
    )
  }

  const handleReleaseDoctorCode = () => {
    setPackageFormValues({
      package_id: null,
      doctor_code: null,
    })
  }

  const handlePackageFormSubmit = (formValues, castValues) => {
    setPackageFormValues(Object.assign(packageFormValues, formValues))
    setOrder(Object.assign(order, castValues))

    nextPage()
    window.scrollTo(0, 0)
  }

  const handleShipmentFormSubmit = (formValues, castValues) => {
    setShipmentFormValues(Object.assign(shipmentFormValues, formValues))
    setOrder(Object.assign(order, castValues))

    nextPage()
    window.scrollTo(0, 0)
  }

  const handlePatientFormSubmit = (formValues, castValues) => {
    setPatientFormValues(Object.assign(patientFormValues, formValues))

    setOrder(
      Object.assign(order, castValues, {
        environment: {
          ...castValues.environment,
          estimated_childbirth_date:
            order.environment.estimated_childbirth_date,
        },
      })
    )

    return calculateOrderPrice()
  }

  const calculateOrderPrice = () => {
    const params = {
      estimated_childbirth_date: order.environment.estimated_childbirth_date,
      doctor_code: order.doctor_code,
      package_id: order.package_id,
      shipping_method_id: order.shipping_method_id,
    }

    return trackPromise(
      axios
        .get(`${process.env.GATSBY_API_URL}/subscription/order/calculate`, {
          params,
        })
        .then(response => {
          setOrder(Object.assign(order, response.data))
          nextPage()
          window.scrollTo(0, 0)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgPriceError",
              defaultMessage: "Nie udało się obliczyć zamówienia...",
            }),
            4000
          )
        )
    )
  }

  const handlePlaceOrder = () => {
    trackPromise(
      axios
        .post(`${process.env.GATSBY_API_URL}/user/registration`, order)
        .then(response => {
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgOrderSuccess",
              defaultMessage:
                "Zamówienie złożone pomyślnie za chwilę nastąpi przekierowanie do płatności...",
            }),
            4000
          )
          setTimeout(() => {
            window.location.href = response.data.payment_url
          }, 2500)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgOrderError",
              defaultMessage: "Nie udało się złożyć zamówienia...",
            }),
            4000
          )
        )
    )
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
    window.scrollTo(0, 0)
  }

  return (
    <Box
      className="order-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container disableGutters={true}>
        <Box component="h2" textAlign="center">
          <FormattedMessage
            id="orderBanner_title"
            defaultMessage="Zamówienie"
          />
        </Box>
        <OrderStepper currentOrderStep={page}></OrderStepper>
        {page === 0 && (
          <PregnancyDateForm
            handleSubmitting={handlePregnancyDateFormSubmit}
            values={pregnancyDateFormValues}
          ></PregnancyDateForm>
        )}
        {page === 1 && (
          <PackageForm
            handleCheckDoctorCode={handleCheckDoctorCode}
            handleReleaseDoctorCode={handleReleaseDoctorCode}
            handleSubmitting={handlePackageFormSubmit}
            handleAbort={previousPage}
            values={packageFormValues}
            packages={packages}
          ></PackageForm>
        )}
        {page === 2 && (
          <ShipmentForm
            handleSubmitting={handleShipmentFormSubmit}
            handleAbort={previousPage}
            values={shipmentFormValues}
          ></ShipmentForm>
        )}
        {page === 3 && (
          <PatientForm
            handleSubmitting={handlePatientFormSubmit}
            handleAbort={previousPage}
            values={patientFormValues}
          ></PatientForm>
        )}
        {page === 4 && (
          <OrderSummary
            order={order}
            packages={packages}
            handleAbort={previousPage}
            handleSubmitting={handlePlaceOrder}
          ></OrderSummary>
        )}
      </Container>
    </Box>
  )
}

export default withSnackbar(OrderBanner, snackBarSettings)
