import React from "react"
import PropTypes from "prop-types"
import { Calendar4 } from "react-bootstrap-icons"

import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PackageSummary = props => {
  const { packageDetails, discount } = props

  const discountPriceContent = (
    <p className="package-pricing">
      <span className="old-price">
        <strike>{packageDetails.price}</strike>
      </span>
      <span className="new-price">
        {packageDetails.discount_price}
        <sup>{packageDetails.currency}</sup>
        <sub>
          <FormattedMessage id="package_brutto" defaultMessage="brutto" />
        </sub>
      </span>
    </p>
  )

  const priceContent = (
    <p className="package-pricing">
      {packageDetails.price}
      <sup>{packageDetails.currency}</sup>
      <sub>
        <FormattedMessage id="package_brutto" defaultMessage="brutto" />
      </sub>
    </p>
  )

  return (
    <Paper className="package-summary" square={true}>
      <Grid container className="package-content" alignItems="stretch">
        <Grid item xs={12}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            boxSizing="border-box"
          >
            <Calendar4 className="calendar-icon"></Calendar4>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={3}
            height="100%"
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
          >
            <p className="package-name">{packageDetails.name}</p>
            <p className="package-description">{packageDetails.description}</p>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            height="100%"
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            {discount ? discountPriceContent : priceContent}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

PackageSummary.propTypes = {
  packageDetails: PropTypes.object.isRequired,
  discount: PropTypes.bool.isRequired,
}

export default PackageSummary
