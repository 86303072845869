import React from "react"
import Stepper from "react-stepper-horizontal"
import { useIntl } from "gatsby-plugin-react-intl"
import { Hidden } from "@material-ui/core"

const OrderStepper = ({ currentOrderStep }) => {
  const intl = useIntl()
  const steps = [
    {
      title: intl.formatMessage({
        id: "orderBanner_pregnancyDateStep",
        defaultMessage: "Kiedy rodzisz?",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_packageStep",
        defaultMessage: "Twój pakiet",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_shipmentStep",
        defaultMessage: "Dostawa i płatność",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_patientStep",
        defaultMessage: "Twoje dane",
      }),
    },
    {
      title: intl.formatMessage({
        id: "orderBanner_orderStep",
        defaultMessage: "Zamów",
      }),
    },
  ]

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={currentOrderStep}
      defaultBorderColor="#e0e0e0"
      activeBorderColor="#f98867"
      completeBorderColor="#f98867"
      defaultColor=""
      activeColor=""
      completeColor=""
      completeBarColor="#f98867"
      activeBarColor="#f98867"
      activeTitleColor="#1b2a65"
      completeTitleColor="#1b2a65"
      defaultBorderStyle="solid"
      completeBorderStyle="solid"
      activeBorderStyle="solid"
      circleFontSize={0}
    />
  )

  const mobileStepper = (
    <Stepper
      steps={steps}
      activeStep={currentOrderStep}
      defaultBorderColor="#e0e0e0"
      activeBorderColor="#f98867"
      completeBorderColor="#f98867"
      defaultColor=""
      activeColor=""
      completeColor=""
      completeBarColor="#f98867"
      activeBarColor="#f98867"
      activeTitleColor="#1b2a65"
      completeTitleColor="#1b2a65"
      defaultBorderStyle="solid"
      completeBorderStyle="solid"
      activeBorderStyle="solid"
      circleFontSize={0}
      titleFontSize={12}
    />
  )

  return (
    <>
      <Hidden implementation="css" mdDown={true}>
        <div className="order-stepper">{stepper}</div>
      </Hidden>
      <Hidden implementation="css" lgUp={true}>
        <div className="order-stepper">{mobileStepper}</div>
      </Hidden>
    </>
  )
}

export default OrderStepper
