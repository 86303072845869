import { useStaticQuery, graphql } from "gatsby"

export const useOrderDictionary = () => {
  const data = useStaticQuery(graphql`
    query MyPackageQuery {
      allShippingMethod {
        nodes {
          shipping_method_id
          name
          price
          currency
        }
      }
      allPaymentMethod {
        nodes {
          payment_method_id
          name
        }
      }
      allLanguage {
        nodes {
          language_cd
          language_name
        }
      }
    }
  `)

  const shippingMethods = data.allShippingMethod.nodes
  const paymentMethods = data.allPaymentMethod.nodes
  const languages = data.allLanguage.nodes

  return { shippingMethods, paymentMethods, languages }
}
