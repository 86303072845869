import React from "react"
import { useOrderDictionary } from "../../../dictionaries/orderDictionaries"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PaymentMethodFormPart = formikProps => {
  const { handleChange, handleBlur, values } = formikProps
  const { paymentMethods } = useOrderDictionary()
  return (
    <>
      <Box mb={3}>
        <h4>
          <FormattedMessage
            id="paymentForm_title"
            defaultMessage="Metoda płatności"
          />
        </h4>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box className="payment-radio-field" display="flex" flexWrap="wrap">
            {paymentMethods.map((element, index) => {
              return (
                <>
                  <input
                    type="radio"
                    key={index}
                    id={element.payment_method_id}
                    name="payment_method_id"
                    value={element.payment_method_id}
                    checked={
                      values.payment_method_id === element.payment_method_id
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor={element.payment_method_id}>
                    <img
                      src={`../../../../order-assets/${element.name}.png`}
                      alt={element.name}
                      height="96px"
                    ></img>
                  </label>
                </>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentMethodFormPart
