import React, { useEffect, useState } from "react"

import { Fade } from "react-reveal"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const DoctorCode = props => {
  const { handleCheckDoctorCode, handleReleaseDoctorCode, doctorCode } = props

  const [rawDoctorCode, setRawDoctorCode] = useState("")

  const [doctorCodeLocked, setDoctorCodeLocked] = useState(false)

  const onCheckCodeClick = event => {
    event.preventDefault()
    handleCheckDoctorCode(rawDoctorCode)
  }

  const onReleaseCodeClick = event => {
    event.preventDefault()
    handleReleaseDoctorCode()
  }

  const onCodeTextChange = event => {
    event.preventDefault()
    setRawDoctorCode(event.target.value)
  }

  useEffect(() => {
    if (doctorCode && doctorCode != "") {
      setDoctorCodeLocked(true)
      setRawDoctorCode(doctorCode)
    } else {
      setDoctorCodeLocked(false)
      setRawDoctorCode("")
    }
  }, [doctorCode])

  const lockedDoctorCodeContent = (
    <Fade>
      <Box
        display="flex"
        flexWrap="wrap"
        my={5}
        alignItems="center"
        justifyContent="center"
      >
        <Box component="p" m={1}>
          <FormattedMessage
            id="doctorCode_lblYourCode"
            defaultMessage="Twój kod lekarza:"
          />
          <b>{doctorCode}</b>
        </Box>
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={onReleaseCodeClick}
          >
            <FormattedMessage
              id="doctorCode_btnUseAnotherCode"
              defaultMessage="Skorzystaj z innego"
            />
          </Button>
        </Box>
      </Box>
    </Fade>
  )

  const emptyDoctorCodeContent = (
    <Box display="flex" flexDirection="column" my={5} alignItems="center">
      <FormattedMessage
        id="doctorCode_lblUserDoctorCode"
        defaultMessage="Posiadasz kod lekarza? Użyj go by uzyskać rabat"
      />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box m={1}>
          <TextField
            name="doctor_code"
            variant="outlined"
            value={rawDoctorCode ? rawDoctorCode : ""}
            onChange={onCodeTextChange}
            fullWidth
          />
        </Box>

        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={onCheckCodeClick}
          >
            <FormattedMessage
              id="doctorCode_btnUseDoctorCode"
              defaultMessage="Użyj"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )

  if (doctorCodeLocked) {
    return lockedDoctorCodeContent
  } else {
    return emptyDoctorCodeContent
  }
}

export default DoctorCode
